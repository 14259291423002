<template>
  <div class="tool-head">
    <div class="history" v-if="userInfo && userInfo.id" @click="getlist">
      历史
    </div>
    <slot></slot>
    <van-popup v-model="show" position="top">
      <div class="history-content">
        <div class="content-head">
          <span>历史记录</span>
          <span @click="clear">清空</span>
        </div>
        <van-loading v-if="loading" />
        <ul class="content-body scroll-panel" @scroll="handleScroll" v-else>
          <li
            class="content-item"
            v-for="(item, index) in historyList"
            :key="index"
          >
            <span class="content-text" @click="clickItem(item)">{{
              item.createTime + item.title
            }}</span>
            <i class="iconfont icon-clear-2" @click="delItem(item, index)"></i>
          </li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'tool-head',
  props: {
    type: {
      type: String,
      default: 'lawyerFee'
    }
  },
  computed: {
    ...mapState('user', ['userInfo'])
  },
  data() {
    return {
      show: false,
      historyList: [],
      pageSize: 10,
      pageNo: 1,
      hasMore: true,
      loading: false,
      timer: null
    }
  },
  methods: {
    clear() {
      if (this.historyList && this.historyList.length) {
        this.$dialog
          .confirm({
            title: '',
            message: '历史记录无法找回'
          })
          .then(() => {
            // on confirm
            this.$axios
              .get(
                `${this.$base}/management/utils/history/delAll?type=${
                  this.type
                }`
              )
              .then(res => {
                if (res.data.code === 200) {
                  this.$notify({
                    message: '清空成功',
                    type: 'success'
                  })
                  this.historyList = []
                  this.show = false
                } else {
                  this.$notify(res.data.message)
                  this.show = false
                }
              })
              .catch(err => {
                console.log(err)
                this.show = false
              })
          })
          .catch(() => {
            // on cancel
          })
      } else {
        this.$notify('暂无数据')
      }
    },
    delItem(item, index) {
      this.$axios
        .get(`${this.$base}/management/utils/history/delOne?id=${item.id}`)
        .then(res => {
          if (res.data.code === 200 && res.data.data) {
            console.log(res.data.data)
            this.historyList.splice(index, 1)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    clickItem(item) {
      this.$emit('clickItem', item)
    },
    getlist() {
      this.show = true
      this.hasMore = true
      this.pageNo = 1
      console.log(this.hasMore, this.pageNo, '+++++++ hasMore +++++++')
      this.historyList = []
      this.getHistoryList()
    },
    getHistoryList() {
      console.log(this.hasMore, this.pageNo, '----- hasMore ----')
      // const ua = navigator['userAgent']
      // let terminalType = 'PC'
      // if (ua === this.$ua.a) {
      //   terminalType = 'IOS'
      // } else if (ua === this.$ua.i) {
      //   terminalType = 'ADNROID'
      // }
      if (this.hasMore) {
        const params = {
          type: this.type,
          // terminalType,
          pageNo: this.pageNo,
          pageSize: this.pageSize
        }
        this.loading = true
        this.$axios
          .get(`${this.$base}/management/utils/history/queryByUserId`, {
            params
          })
          .then(res => {
            if (res.data.code === 200 && res.data.data) {
              this.historyList = [...this.historyList, ...res.data.data.content]
            }
            console.log(
              res.data.data.content,
              this.pageNo,
              res.data.data.totalPage,
              '---------'
            )
            if (this.pageNo >= res.data.data.totalPage) {
              this.hasMore = false
            } else {
              this.hasMore = true
            }
            this.loading = false
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      }
    },
    handleScroll() {
      this.pageNo += 1
      this.getHistoryList()
    }
  },
  created() {
    if (this.userInfo && this.userInfo.id) {
      console.log('created')
      this.getHistoryList()
    }
  },
  mounted() {
    this.$bus.$on('add-history-suc', type => {
      console.log('mounted:>>>>>', type)
      if (type === this.type) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.pageNo = 1
          this.pageSize = 10
          this.hasMore = true
          this.historyList = []
          if (this.userInfo && this.userInfo.id) {
            this.getHistoryList()
          }
        }, 500)
      }
    })
  }
}
</script>

<style lang="stylus" scoped>
.tool-head
  display flex
  justify-content flex-end
  align-items center
  .history
    flex 1
    text-align right
    line-height 40px
    color #0079fe
    cursor pointer
    margin-right 10px
.history-content
  padding 0 10px
  font-size 12px
  .content-head
    color #ccc
    height 40px
    line-height 40px
    cursor pointer
    display flex
    justify-content space-between
  .van-loadinig
    min-height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
  .content-body
    color #666
    max-height 290px
    overflow-y auto
    .content-item
      height 30px
      line-height 30px
      cursor pointer
      display flex
      justify-content space-between
      .content-text
        flex 1
        text-align left
        overflow: hidden; // 超出的文本隐藏
        text-overflow: ellipsis; // 溢出用省略号显示
        white-space: nowrap; // 溢出不换行
      .icon-clear-2
        width 30px
        text-align center
</style>
<style scope>
.scroll-panel::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.scroll-panel::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #afafb1;
  border-radius: 3px;
}
.scroll-panel::-webkit-scrollbar-track-piece {
  background-color: transparent;
  border-radius: 3px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
