var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tool-head" },
    [
      _vm.userInfo && _vm.userInfo.id
        ? _c("div", { staticClass: "history", on: { click: _vm.getlist } }, [
            _vm._v(" 历史 "),
          ])
        : _vm._e(),
      _vm._t("default"),
      _c(
        "van-popup",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            { staticClass: "history-content" },
            [
              _c("div", { staticClass: "content-head" }, [
                _c("span", [_vm._v("历史记录")]),
                _c("span", { on: { click: _vm.clear } }, [_vm._v("清空")]),
              ]),
              _vm.loading
                ? _c("van-loading")
                : _c(
                    "ul",
                    {
                      staticClass: "content-body scroll-panel",
                      on: { scroll: _vm.handleScroll },
                    },
                    _vm._l(_vm.historyList, function (item, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "content-item" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "content-text",
                              on: {
                                click: function ($event) {
                                  return _vm.clickItem(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.createTime + item.title))]
                          ),
                          _c("i", {
                            staticClass: "iconfont icon-clear-2",
                            on: {
                              click: function ($event) {
                                return _vm.delItem(item, index)
                              },
                            },
                          }),
                        ]
                      )
                    }),
                    0
                  ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }