var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "font-size-comp" },
    _vm._l(_vm.fontSizeList, function (item, i) {
      return _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: item.size === _vm.fontSize,
              expression: "item.size === fontSize",
            },
          ],
          key: i,
          on: { click: _vm.setFontSize },
        },
        [_vm._v(" " + _vm._s(item.label) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }