<template>
  <div class="font-size-comp">
    <div
      @click="setFontSize"
      v-for="(item, i) in fontSizeList"
      :key="i"
      v-show="item.size === fontSize"
    >
      {{ item.label }}
    </div>
  </div>
</template>
<script>
import AdapterUtil from './AdapterUtil'
export default {
  name: 'font-size-comp',
  components: {},
  data() {
    return {
      fontSizeList: [
        {
          label: '小',
          size: 14
        },
        {
          label: '中',
          size: 18
        },
        {
          label: '大',
          size: 20
        }
      ], // 字体
      fontSize: 14 // 当前的字体
    }
  },
  computed: {},
  methods: {
    IsIOS() {
      var userAgentInfo = navigator.userAgent
      var Agents = ['iPhone', 'iPad', 'iPod']
      var flag = false
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = true
          break
        }
      }
      return flag
    },
    // 点击设置字体大小
    setFontSize() {
      let index = 0
      for (let i = 0; i < this.fontSizeList.length; i++) {
        if (this.fontSize === this.fontSizeList[i].size) {
          index = i + 1
        }
      }
      if (index === this.fontSizeList.length) {
        index = 0
      }
      this.fontSize = this.fontSizeList[index].size
      let fontSize = this.fontSize
      // 不在APP内且是IOS环境
      if (this.$ua.i !== navigator.userAgent && this.IsIOS()) {
        // 适配
        fontSize = AdapterUtil.setSpText(fontSize)
        fontSize += 15
      }
      // console.log(fontSize)
      var list = document.getElementById('tools').getElementsByTagName('*')
      for (let i = 0; i < list.length; i++) {
        if (
          list[i].className === 'van-icon van-icon-success' ||
          list[i].className === 'van-checkbox__icon van-checkbox__icon--square'
        ) {
        } else {
          list[i].style.setProperty('font-size', fontSize + 'px', 'important')
        }
      }
      this.$emit('setFontSize', fontSize, index)
      // this.$emit('currentIndex', index)
    }
  },
  mounted() {},
  created() {}
}
</script>

<style lang="stylus">
.font-size-comp
  font-size 14px !important
  width 100%
  display flex
  align-items center
  justify-content flex-end
  & > div
    color #FFF
    background #CCC
    padding 10px 20px
    cursor pointer
</style>
